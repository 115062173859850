<template>
  <v-container id="accounts" fluid tag="section">
    <v-spacer />
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          color="secondary"
          item-color="secondary"
          label="Selecione a conta"
          :items="accountsList"
          v-model="selectedAccount"
          item-text="account"
          item-value="id"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn @click="loadTables">Carregar tabelas</v-btn>
      </v-col>
      <v-col cols="12" sm="1" offset-sm="2">
        <v-btn
          @click="confirmDeleteTable"
          color="error"
          class="text-end ml-sm-n6"
          >Excluir tabela</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center failure--text">
        <v-progress-linear
          :indeterminate="true"
          height="30"
          :active="notLoading ? false : true"
          color="deep-purple darken-4"
        >
          <v-subheader class="white--text display-1"> Aguarde... </v-subheader>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="notLoading"
          :headers="headers"
          :items="tablesList"
          :single-select="true"
          show-select
          :items-per-page="10"
          class="elevation-1"
          item-key="id"
          v-model="selectedTable"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="confirmDelete" max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir tabela </v-card-title>

        <v-card-text>
          Deseja realmente excluir a tabela
          {{ selectedTable[0] ? selectedTable[0].id : "" }} -
          {{ selectedTable[0] ? selectedTable[0].description : "" }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="confirmDelete = false">
            Não
          </v-btn>

          <v-btn color="red darken-1" text @click="deleteTable"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successDelete" max-width="290">
      <v-card>
        <v-card-title class="headline"> Tabela excluída. </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="successDelete = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="progressDelete" max-width="290">
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          height="30"
          :active="true"
          color="deep-purple darken-4"
        >
          <v-subheader class="white--text display-1">
            Excluindo tabela...
          </v-subheader>
        </v-progress-linear>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { TablesService } from "@/code/services/tables/TablesService";
import { AccountService } from "@/code/services/user/accountService";

export default {
  data() {
    return {
      loading: false,
      selectedAccount: 0,
      selectedTable: [],
      confirmDelete: false,
      successDelete: false,
      progressDelete: false,
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Tipo",
          sortable: false,
          value: "table_type",
        },
        {
          text: "Tipo de produto",
          sortable: false,
          value: "product_type_label",
        },
        {
          text: "Data criação",
          sortable: false,
          value: "created",
        },
        {
          text: "Data modificação",
          sortable: false,
          value: "modified",
        },
      ],
      tablesList: [],
      accountsList: [],
    };
  },
  mounted() {
    this.loadAccounts();
  },
  methods: {
    loadAccounts: async function () {
      this.accountsList = [];
      const accountService = new AccountService();
      this.accountsList = await accountService.getAllAccounts(false);
    },
    loadTables: async function () {
      if (this.selectedAccount && this.selectedAccount > 0) {
        this.loading = true;
        const service = new TablesService();
        this.tablesList = await service.getAllByAccount(this.selectedAccount);
        this.loading = false;
      }
    },
    confirmDeleteTable: function () {
      if (this.selectedTable && this.selectedTable[0]) {
        this.confirmDelete = true;
      }
    },
    deleteTable: async function () {
      this.confirmDelete = false;
      this.progressDelete = true;
      const service = new TablesService();
      var sucesso = service.delete(this.selectedTable[0].id);
      this.progressDelete = false;
      if (sucesso) {
        this.tablesList.splice(
          this.tablesList.indexOf(this.selectedTable[0]),
          1
        );
        this.successDelete = true;
        this.selectedTable = [];
      }
    },
  },
  computed: {
    notLoading() {
      return !this.loading;
    },
  },
};
</script>

<style>
</style>